import React, {useEffect, useState} from 'react';
import "./style.scss"

import Grid from '@material-ui/core/Grid';
import DeviceCard from "../../components/DeviceCard/index.jsx";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import moreIcon from "../../assets/images/down-arrow.svg";

import apiService from '../../services/request';

const Devices = ({ devices }) => {
	const [selectedClient, setSelectedClient] = useState('all')
	const [selectedCountry, setSelectedCountry] = useState('all')
	const [selectedType, setSelectedType] = useState('all')
	const [aliasFilter, setAliasFilter] = useState("");
	const [clients, setClients] = useState([]);
	const [countries, setCountries] = useState([]);
	const [deviceTypes, setDeviceTypes] = useState([]);
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

	useEffect(() => {
		apiService.getDashboardData().then(data => {
			setClients(data['clients']);
			setCountries(data['countries'])
			setDeviceTypes(data['types'])
		}).catch(e => console.log(e))
	}, [])

	let filteredDevices = devices.concat().sort((a, b) => {
		if (a.active && !b.active) {
			return -1
		} else if (!a.active && b.active) {
			return 1
		} else {
			return 0
		}
	});
	if (selectedClient !== 'all') {
		filteredDevices = filteredDevices.filter(d => d.client === selectedClient)
	}
	if (selectedCountry !== 'all') {
		filteredDevices = filteredDevices.filter(d => d.country === selectedCountry)
	}
	if (selectedType !== 'all') {
		filteredDevices = filteredDevices.filter(d => d.device_type === selectedType)
	}
	if (aliasFilter) {
		filteredDevices = filteredDevices.filter(d => {
			const nameToFilter = (d.alias || "");
			const deviceFilter = d.serial_number;
			return nameToFilter.toUpperCase().includes(aliasFilter.toUpperCase()) || deviceFilter.toUpperCase().includes(aliasFilter.toUpperCase())
		})
	}

	return(
		<main className="main-section devices-section">
			<div className="section-header">
				<div className="title">
					<h3>Find devices</h3>
				</div>
			</div>

			<div className="list-filters">
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={4} xl={3}>
						<div className="type-select">
							<span className="date-header-label">Client:</span>

							<Select labelId="demo-simple-select-label-01" id="demo-simple-select-01"
											value={selectedClient} onChange={(e) => {setSelectedClient(e.target.value)}}>
								<MenuItem value={'all'}>All</MenuItem>
								{clients.map(c => <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>)}
							</Select>
							<img src={moreIcon} alt=""/>
						</div>
					</Grid>

					<Grid item xs={2} md={2} lg={2} xl={2}>
						<div className="type-select">
							<span className="date-header-label">Country:</span>
							<Select labelId="demo-simple-select-label-02" id="demo-simple-select-02"
											value={selectedCountry} onChange={(e) => {setSelectedCountry(e.target.value)}}>
								<MenuItem value={'all'}>All</MenuItem>
								{countries.map(c => <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>)}
							</Select>
							<img src={moreIcon} alt=""/>
						</div>
					</Grid>

					<Grid item xs={2} md={2} lg={2} xl={2}>
						<div className="type-select">
							<span className="date-header-label">Type:</span>
							<Select labelId="demo-simple-select-label-03" id="demo-simple-select-03"
											value={selectedType} onChange={(e) => {setSelectedType(e.target.value)}}>
								<MenuItem value={'all'}>All</MenuItem>
								{deviceTypes.map(c => <MenuItem key={c.id} value={c.name}>{c.name}</MenuItem>)}
							</Select>
							<img src={moreIcon} alt=""/>
						</div>
					</Grid>

					<Grid item xs={2} md={2} lg={2} xl={2}>
							<TextField
								className="text-field"
								label="Device Alias / SN"
								variant="outlined"
								size="small"
								value={aliasFilter}
								onChange={(e) => setAliasFilter(e.target.value)}
							/>
					</Grid>
				</Grid>

			</div>

			<div className="device-list-container">
				<div className="devices-list">
					<Grid container spacing={3}>
						{filteredDevices.map(device => <DeviceCard key={device.id} device={device}/>)}
					</Grid>
				</div>
			</div>

			<Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Mensaje importante</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Este es un mensaje importante que se administrará desde la administración.</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Cerrar</Button>
                </DialogActions>
            </Dialog>
		</main>
	)

}

export default Devices;
